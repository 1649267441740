import BaseBean from "@/utils/BaseBean";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import XyzCard from "@/views/project/order/finance/xyz/xyz/XyzCard.vue";
import OaApplyCard from "@/views/project/order/finance/oa/oaApply/OaApplyCard.vue";
import TtPayCard from "@/views/project/order/finance/tt/ttPay/TtPayCard.vue";
import TtOaCard from "@/views/project/order/finance/tt/ttOa/TtOaCard.vue";
export interface IUnlockOrderListDataObj {
    utilInst:UnlockOrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class UnlockOrderListUtil extends BaseBean{
    public dataObj:IUnlockOrderListDataObj

    constructor(proxy:any,dataObj:IUnlockOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{

    }
    //查看pi弹出框
    public async piDetail(piId:string):Promise<any>{
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:this.proxy,cardFrom:'UnlockOrderList',
            title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }
    //查看单据弹出框
    public async viewBill(id:string,srcType:number):Promise<any>{
        let title:string='TT支付';
        let modelComp:any=TtPayCard;
        switch (srcType) {
            case 1:title='信用证支付';modelComp=XyzCard;break;
            case 2:title='OA支付';modelComp=OaApplyCard;break;
            case 3:title='信保回款';modelComp=TtOaCard;break;
        }
        await this.utils.UtilPub.openDialog({proxy:this.proxy,addOrEdit:'edit',id:id,modelComp:modelComp,ownerComp:this.proxy,cardFrom:'UnlockOrderList',
            title:title,contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
    }

    //订单解锁预付款
    public async unlockKxHandler():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        if(selections.length==0){
            this.proxy.$message('请选择要操作的行');
            return ;
        }
        this.dataObj.otherParams.selInfo.F_PI_ID=selections[0].F_PI_ID;
        this.dataObj.otherParams.selInfo._F_SRC_TYPE=selections[0]._F_SRC_TYPE;
        this.dataObj.otherParams.selInfo.F_ORDER_CODE=selections[0].F_ORDER_CODE;
        this.dataObj.otherParams.selInfo.F_AMOUNT=selections[0].F_AMOUNT;
        this.dataObj.otherParams.selInfo.F_JS_TYPE=(selections[0].F_JS_TYPE=='人民币'?'RMB':'$');
        this.dataObj.otherParams.dialogVisible=true;
    }
    //解锁确认发起请求处理
    public async doUnlock():Promise<any>{
        let selections:Array<any>=this.dataObj.pageListRef.getTbInst().getSelection();
        let params={id:selections[0].F_ID};
        let res=await this.utils.OrderProviderApi.unlockOrderAction({params:params});
        if(res.result){
            this.dataObj.pageListRef.queryHandler(true);
            this.utils.Tools.success();
            this.dataObj.otherParams.dialogVisible=false;
        }else if(!res.msg){//tmd,当后台返回失败且msg为null的时候，美元任何提示
            this.utils.Tools.error();
        }
    }

}