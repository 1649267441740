import {reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, nextTick} from 'vue';
import UnlockOrderListUtil ,{IUnlockOrderListDataObj} from './unlockOrderListUtil';

export default defineComponent ({
    name: 'UnlockOrderList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUnlockOrderListDataObj=reactive<IUnlockOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                cardFrom:'UnlockOrderList',
                isShowFixCol:false,
                modelMethod: utils.OrderProviderApi.buildUrl('/unlockOrder/pageData')
            },
            otherParams:{
                dialogVisible:false,
                selInfo:{
                    F_PI_ID:'',//piId
                    _F_SRC_TYPE:'',//确认类别
                    F_ORDER_CODE:'',//订单号
                    F_AMOUNT:0,//金额
                    F_JS_TYPE:'RMB',//结算币种
                },
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new UnlockOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {

            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});